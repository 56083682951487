import React from 'react'
import { Card, Layout } from '../components'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <Card>
      <h1 style={{ textAlign: 'center' }}>NOT FOUND {'🤷🏻‍♂️'}</h1>
      <p>
        Doesn't look like there is anything very exciting here.{' '}
        <Link to="/">Take me back home!</Link>
      </p>
    </Card>
  </Layout>
)

export default NotFoundPage
